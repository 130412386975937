import React, {useState} from "react";
import {generateRandomString} from "./string_utils";
import {getOrElse} from "./url_param_utils";
import {useReactPath} from "./path_hook";


export const useParam = (key: string, defaultValue ?: any) => {

    const path = useReactPath();
    const [value, setValue] = useState<any>(undefined)

    React.useEffect(() => {
        getOrElse( new URL(path), key, setValue, defaultValue ?? undefined)
    }, [path]);

    return value

}
