import React, {useState} from "react";
import { generateRandomString } from "./string_utils";
import {useNavigate} from "react-router-dom";
import {useReactPath} from "./path_hook";

export interface OAuthParameters {
    "client_id": string
    "redirect_uri": string,
    "scope": string,
    "state": string,
    "response_type": string
}

export const useOAuthParameters: () => OAuthParameters | undefined = () => {

    const path = useReactPath();
    const navigate = useNavigate()
    const [loaded, setLoaded] = useState(false)
    const [clientId, setClientId] = useState<string | undefined>()
    const [redirectUri, setRedirectUri] = useState<string | undefined>()
    const [state, setState] = useState<string | undefined>()
    const [scope, setScope] = useState<string | undefined>()
    const [responseType, setResponseType] = useState<string | undefined>()
    const getOrElse = (url: URL, key: string, fn: (value: string | undefined) => void, orElse : string | undefined = undefined) => {
        if (url.searchParams.has(key)) {
            fn((url.searchParams.get(key) as string))
        } else {
            fn(orElse)
        }
    }

    React.useEffect(() => {
        // do something when path changes ...
        let url = new URL(path)
        getOrElse(url, "client_id", setClientId)
        getOrElse(url, "redirect_uri", setRedirectUri)
        getOrElse(url, "state", setState, generateRandomString(16))
        getOrElse(url, "scope", setScope)
        getOrElse(url, "response_type", setResponseType)
        setLoaded(true)
    }, [path]);


    if (clientId && redirectUri) {
        // OAUth parameters are considered to be present if all mandatory ones are present

        return {
            client_id: clientId,
            redirect_uri: redirectUri,
            scope: scope,
            response_type: responseType,
            state: state,
        } as OAuthParameters
    } else return undefined
}

