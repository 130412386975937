import {Button, Paper, Flex, Image, Text, PasswordInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useLocation, useNavigate} from "react-router-dom";
import {Background} from "../components/background";
import "../api/reset_password";
import {resetPassword} from "../api/reset_password";
import {notifications} from "@mantine/notifications";
import {IconLockOff, IconThumbUp} from "@tabler/icons-react";
import {useParam} from "../utils/code_hook";
import {useCallback} from "react";

export const ResetPassword = (props: {}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const code = useParam("code")
    const form = useForm({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validate: {
            password: (value) =>
                value.length < 8 ? "Must be at least 8 characters long" : null,
            confirmPassword: (value, values) =>
                value !== values.password ? "Passwords did not match" : null,
        },
    });

    const confirm_password = useCallback((password: string) => {
        resetPassword(code, password)
            .then((response) => {
                // @ts-ignore
                window.location = parameters!.redirect_uri + "?code=" + response;
                notifications.show({
                    title: "Success",
                    message: "Your password has been successfully reset. You can now login with the new credentials",
                    autoClose: 2500,
                    color: "green",
                    icon: <IconThumbUp size={18}/>,
                });
                navigate("/reset-success" );
            })
            .catch((error) => {
                notifications.show({
                    title: "Action failed",
                    message: error.message,
                    autoClose: 2500,
                    color: "red",
                    icon: <IconLockOff size={18}/>,
                });
            });
    }, [code]);

    //idk what to put instead of code

    return (
        <Background>
            <Paper w={320} p={36} shadow={"md"} pos={"relative"}>
                <Flex align={"stretch"} direction={"column"} justify={"center"}>
                    <Image src={"accuras_logo.png"} pr={24} pl={24} mb={24}/>
                    <form
                        onSubmit={form.onSubmit((values) => {
                            //console.log(values)
                            form.setValues(values);
                            confirm_password(values.password);
                        })}
                    >
                        <Flex justify="center" direction="column" gap="md">
                            <PasswordInput
                                {...form.getInputProps("password")}
                                label={"Password"}
                                placeholder={"Your password"}
                            />

                            <PasswordInput
                                {...form.getInputProps("confirmPassword")}
                                label={"Confirm password"}
                                placeholder={"Repeat your password"}
                            />

                            <Button
                                type={"submit"}

                            >
                                Confirm
                            </Button>
                        </Flex>
                    </form>
                </Flex>
            </Paper>
        </Background>
    );
};
