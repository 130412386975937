import {OAuthParameters} from "../utils/oauth_hook";


export const authenticate = (email: string, password :string, parameters: OAuthParameters) => {
    return fetch(process.env.REACT_APP_ACCURAS_OAUTH_HOST + "/authenticate", {
        method: "POST",
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
            email: email,
            password: password,
            redirect_uri: parameters!.redirect_uri,
            client_id: parameters!.client_id,
            state: parameters!.state,
            scope: parameters!.scope,
            response_type: parameters!.response_type
        }),
    })
        .then(response => {
            if (!response.ok) {
                return response.text().then(text => {
                    throw new Error(text)
                })
            } else {
                return response.text();
            }
        })

}