export const requestPasswordResetLink = (email: string) => {
    return fetch(process.env.REACT_APP_ACCURAS_OAUTH_HOST + "/forgot-password", {
        method: "POST",
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
            email: email,
        }),
    }).then(response => {
        if (!response.ok) {
            return response.text().then(text => {
                throw new Error(text)
            })
        } else {
            return response.text();
        }
    })

}