import {Paper, Box, Image, Button, TextInput, Title, Text, Flex, LoadingOverlay} from "@mantine/core";
import {useForm} from "@mantine/form";
import React, {useCallback, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Background} from "../components/background";
import {notifications} from "@mantine/notifications";
import {IconLockOff} from "@tabler/icons-react";
import {createAccount} from "../api/create_account";


export const Register: React.FC<{}> = (props) => {

    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: ''
        },
        validate: {
            name: (value: string) => value.length < 4 ? "Must be at least 4 characters long" : null,
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email address'),
            password: (value) => (value.length < 6 ? 'Must be at least 6 characters long' : null),
            confirmPassword: (value, values) => value !== values.password ? 'Passwords did not match' : null,
        },
    });

    const executeCreation = useCallback((name: string, email: string, password: string) => {
        setLoading(true)
        createAccount(name, email, password)
            .then(() => {
                navigate("/register/success" + location.search)
            }).catch(error => {
            notifications.show(
                {
                    "title": "Registration failed",
                    "message": error.message,
                    autoClose: 2500,
                    color: 'red',
                    icon: <IconLockOff size={18}/>,
                }
            )
        })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return <Background>

        <Paper p={36} shadow={"md"} pos={"relative"}>

            <Box pos="absolute" top={0} left={0} right={0} bottom={0}>
                <LoadingOverlay visible={loading} overlayBlur={2}/>
            </Box>

            <Flex align={"center"} mb={24}>

                <Image src={"accuras_icon.png"} maw={32} mah={32}/>

                <Flex ml={12} direction={"column"} justify={"space-between"} align={"space-between"}>

                    <Title size={16}>
                        New account
                    </Title>
                    <Text opacity={0.5} size={12}>
                        at accuras
                    </Text>
                </Flex>

            </Flex>

            <form onSubmit={form.onSubmit((values) => {
                executeCreation(values.name, values.email, values.password)
            })}>
                <Flex direction={"column"} align={"stretch"} gap={"sm"}>

                    <TextInput
                        {...form.getInputProps('name')}
                        label={"Name"}
                        placeholder={"Full name"}/>

                    <TextInput
                        {...form.getInputProps('email')}
                        label={"E-Mail"}
                        placeholder={"E-mail address"}/>

                    <TextInput
                        {...form.getInputProps('password')}
                        label={"Password"}
                        type={"password"}
                        placeholder={"Your password"}/>

                    <TextInput
                        {...form.getInputProps('confirmPassword')}
                        label={"Confirm password"}
                        type={"password"}
                        placeholder={"Repeat your password"}/>
                </Flex>

                <Flex justify={"space-between"} align={"center"} mt={24}>

                    <Button variant={"white"} p={0} mr={12} onClick={() => {
                        navigate("/authorize" + location.search)
                    }}>
                        I already have an account
                    </Button>

                    <Button type={"submit"} ml={12}>
                        Register
                    </Button>

                </Flex>

            </form>

        </Paper>

    </Background>

}