export const resetPassword = (code: string, password: string) => {
    return fetch(process.env.REACT_APP_ACCURAS_OAUTH_HOST + "/reset-password", {
        method: "POST",
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
            password: password,
            code: code,
        }),
    }).then(response => {
        if (!response.ok) {
            return response.text().then(text => {
                throw new Error(text)
            })
        } else {
            return response.text();
        }
    })
}