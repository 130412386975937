import {
    Button,
    Paper,
    Flex,
    Image,
    TextInput,
    Box,
    LoadingOverlay,
    Text,

} from "@mantine/core";
import React, {useCallback, useState} from "react";
import {useForm} from "@mantine/form";
import {useMantineTheme} from "@mantine/core";
import {Background} from "../components/background";
import {notifications} from "@mantine/notifications";
import "../api/forgot_password";
import {IconLockOff, IconThumbUp} from '@tabler/icons-react';
import {useLocation, useNavigate} from "react-router-dom";
import {requestPasswordResetLink} from "../api/forgot_password";
import {useOAuthParameters} from "../utils/oauth_hook";

export const ForgotPassword = (props: {}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const theme = useMantineTheme();
    const parameters = useOAuthParameters()

    const form = useForm({
        initialValues: {
            email: "",
        },
        validate: {
            email: (value) =>
                /^\S+@\S+$/.test(value) ? null : "Invalid email address",
        },
    });

    const requestReset = (email: string) => {
        setLoading(true)
        requestPasswordResetLink(email)
            .then(response => {
                notifications.show({
                    title: "E-Mail sent",
                    message: "A link to reset your password has been sent to your e-mail address",
                    icon: <IconThumbUp size={18}/>,
                    color: "green",
                })
                const params = new URLSearchParams((parameters ?? {}) as Record<string, string>).toString()
                navigate(`/authorize?${params}`)
            })
            .catch(error => {
                notifications.show(
                    {
                        "title": "Action failed",
                        "message": error.message,
                        autoClose: 2500,
                        color: 'red',
                        icon: <IconLockOff size={18}/>,
                    }
                )
            })
            .finally(() => setLoading(false))
    }


    return (
        <Background>
            <Paper w={320} p={36} shadow={"md"} pos={"relative"}>
                <Box pos="absolute" top={0} left={0} right={0} bottom={0}>
                    <LoadingOverlay visible={loading} overlayBlur={2}/>
                </Box>

                <Flex align={"stretch"} direction={"column"} justify={"center"} gap="md">
                    <Image src={"accuras_logo.png"} pr={24} pl={24} mb={24}/>

                    <form onSubmit={form.onSubmit((values) => {
                        form.setValues(values)
                        requestReset(values.email)

                    })}>
                        <Flex justify="center" direction="column" gap="md">
                            <TextInput
                                {...form.getInputProps("email")}
                                type={"email"}
                                placeholder="E-mail address"
                                label="E-Mail"
                            />

                            <Button
                                type={"submit"}
                            >
                                Request password
                            </Button>


                            <Button
                                variant={"white"}
                                color={"dark"}
                                opacity={0.5}
                                onClick={() => {
                                    navigate("/authorize" + location.search);
                                }}
                                h={16}
                                p={0}
                                sx={{
                                    fontSize: 12,
                                    cursor: "pointer",
                                    userSelect: "none",
                                    "&:hover": {
                                        textDecoration: "underline",
                                    },
                                    "&:active": {
                                        opacity: 0.75,
                                    },
                                }}
                            >
                                I know my password
                            </Button>
                        </Flex>
                    </form>
                </Flex>
            </Paper>
        </Background>


    );
};
