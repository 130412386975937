import {
  Button,
  Paper,
  Flex,
  Image,
  Text,
} from "@mantine/core";

import { Background } from "../components/background";


export const Error = (props: {}) => {
  return (
    <Background>
      <Paper w={320} p={36} shadow={"md"} pos={"relative"}>
        <Flex align={"center"} direction={"column"} justify={"center"} gap="md">

          <Image src={"accuras_logo.png"} pr={24} pl={24} mb={24} />

          <Text fz="md" fw={500} ta="center" fs="" c="">
            Something went wrong with your request. Please try again later.
          </Text>

          <Flex justify="center" direction="column" gap="md">
  
          </Flex>
        </Flex>
      </Paper>
    </Background>
  );
};
